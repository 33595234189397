import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Container from 'components/ui/Container';
import * as Styled from './styles';

import { SectionTitle } from 'helpers/definitions';

interface Overview extends SectionTitle {
  content: string;
  linkTo: string;
  linkText: string;
}

const Overview: React.FC = () => {
  const { markdownRemark } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { category: { eq: "overview section" } }) {
        frontmatter {
          title
          subtitle
          content
          linkTo
          linkText
        }
      }
    }
  `);

  const overview: Overview = markdownRemark.frontmatter;

  return (
    <Container section>

      <div class="min-w-full bg-white max-w-2xl shadow overflow-hidden sm:rounded-lg">
          <div class="px-4 py-5 sm:px-6">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                  会社概要
              </h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                  Details and informations about CampoLungo Inc.
              </p>
          </div>
          <div class="border-t border-gray-200">
              <dl>
                  <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                          会社名
                      </dt>
                      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          合同会社 Campo Lungo<br />
                          <span class="text-xs">ゴウドウガイシャ カンポルンゴ</span>
                      </dd>
                  </div>
                  <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                          設立年月
                      </dt>
                      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          2017年12月
                      </dd>
                  </div>
                  <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                          住所
                      </dt>
                      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          東京都中央区日本橋兜町１７−２
                      </dd>
                  </div>
                  <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                          代表
                      </dt>
                      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          長野 有紗
                      </dd>
                  </div>
              </dl>
          </div>
      </div>

    </Container>
  );
};

export default Overview;
