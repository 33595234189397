import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Overview from 'components/Overview';

const OverviewPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="会社概要" />
      <Overview />
    </Layout>
  );
};

export default OverviewPage;
